import { useState, useEffect } from "react";
import { services } from "../services";

export const useFetch = (endpoint) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      setIsLoading(true);
      const response = await services.Get(endpoint);
      if (response.status === 200) {
        setData(response.data);
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [endpoint]);

  return {
    data,
    isLoading,
    error,
    refetch: fetch,
  };
};
