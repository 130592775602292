import { useState, useRef } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { Icon } from "@iconify/react";
import chevronDown from "@iconify/icons-eva/chevron-down-fill";
import chevronUp from "@iconify/icons-eva/chevron-up-fill";

const DropdownList = ({ title, options, onSelect }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const selectOption = (option) => {
    setOpen(false);
    onSelect(option);
  };

  return (
    <>
      <Button
        ref={ref}
        variant="contained"
        onClick={() => setOpen(!open)}
        sx={{
          width: 170,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        {title}
        <Icon icon={open ? chevronUp : chevronDown} width={20} height={20} />
      </Button>
      <Menu
        sx={{ marginTop: 1 }}
        anchorEl={ref.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        {options.map(({ value, label }) => (
          <MenuItem sx={{ width: 170 }} onClick={() => selectOption(value)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownList;
