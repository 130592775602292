import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import { Link, Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {props.menu === "claimPool" ? (
          <>
            <Link
              className="edit-btn"
              style={{ textDecoration: "none" }}
              to={{
                pathname: props.url,
                query: { data: props.id, isCompleted: props.isCompleted },
                params: { data: props.id, isCompleted: props.isCompleted },
              }}
            >
              <MenuItem sx={{ color: "text.secondary" }}>
                <ListItemIcon>
                  <Icon icon={editFill} width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary="Edit"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            </Link>
          </>
        ) : (
          <>
            <MenuItem sx={{ color: "text.secondary" }}>
              <ListItemIcon>
                <Icon icon={trash2Outline} width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>

            <MenuItem
              component={RouterLink}
              to="#"
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Icon icon={editFill} width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Edit"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="#"
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Icon icon={menu2Fill} width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="View Detail"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
}
