import { useRoutes, useNavigate, Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";

import Login from "./pages/Login";
import ManageClaims from "./pages/ManageClaims";
import AddClaimPools from "./pages/AddClaimPools";
import AddSolanaPools from "./pages/AddSolanaPools";
import AddSuiPools from "./pages/AddSuiPools";
import ManageFeatureFlags from "./pages/ManageFeatureFlags";
import ManageSuiContracts from "./pages/ManageSuiContracts";

export default function Router() {
  const navigate = useNavigate();

  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { index: true, element: <ManageClaims /> },
        {
          path: "manage-feature-flags",
          element: <ManageFeatureFlags />,
        },
        {
          path: "add-claim-pool",
          element: <AddClaimPools navigate={navigate} />,
        },
        {
          path: "edit-claim-pool/:id",
          element: <AddClaimPools navigate={navigate} isCompleted={true} />,
        },
        {
          path: "edit-pending-claim-pool/:id",
          element: <AddClaimPools navigate={navigate} isPending={true} />,
        },
        {
          path: "add-sui-contract",
          element: <AddSuiPools navigate={navigate} />,
        },
        {
          path: "manage-sui-contracts",
          element: <ManageSuiContracts navigate={navigate} />,
        },
        { path: "add-solana-pool", element: <AddSolanaPools /> },
        {
          path: "edit-solana-pool/:id",
          element: <AddSolanaPools navigate={navigate} isCompleted={true} />,
        },
        { path: "*", element: <Navigate to="/dashboard" /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [{ path: "/", element: <Login /> }],
    },
  ]);
}
