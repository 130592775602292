import { Icon } from "@iconify/react";
import { useState, useEffect, useMemo } from "react";
import copyOutline from "@iconify/icons-eva/copy-outline";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import { compactAddress } from "../contractData/function";
import { toaster } from "../utils/toast";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserListHead, UserMoreMenu } from "../components/_dashboard/user";
import USERLIST from "../_mocks_/user";
import { services } from "../services";
import { useFetch } from "../queries/useFetch";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import DropdownList from "src/components/DropdownList";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "srNo", label: "Sr No", alignRight: false },
  { id: "poolName", label: "Pool Name", alignRight: false },
  { id: "contractAddress", label: "Contract Address", alignRight: false },
  { id: "tokenAddress", label: "Token Address", alignRight: false },
  { id: "network", label: "Network", alignRight: false },
  { id: "startTime", label: "Start Date", alignRight: false },
  { id: "endTime", label: "End Date", alignRight: false },
  { id: "phaseNo", label: "Phase No", alignRight: false },
  { id: "rewards", label: "Rewards", alignRight: false },
  { id: "pending", label: "Pending Records", alignRight: false },
  { id: "" },
];

const SORT_OPTIONS = {
  CREATED_AT: "Created At",
  UPDATED_AT: "Updated At",
  NAME: "Name",
  NETWORK: "Network",
  START_TIME: "Start Time",
  END_TIME: "End Time",
};

const NETWORK_OPTIONS = [
  { value: "", label: "All" },
  { value: "polygon", label: "Polygon" },
  { value: "binance", label: "Binance" },
  { value: "ethereum", label: "Ethereum" },
  { value: "solana", label: "Solana" },
  { value: "avalanche", label: "Avalanche" },
  { value: "fantom", label: "Fantom" },
  { value: "arbitrum", label: "Arbitrum" },
  { value: "base", label: "Base" },
  { value: "sui", label: "Sui" },
];

const ManageClaims = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showPending, setShowPending] = useState(false);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [network, setNetwork] = useState("");

  const url = useMemo(() => {
    const params = new URLSearchParams(location.search);

    params.set("page", page + 1);
    if (sortBy) params.set("sortBy", sortBy);
    if (network) params.set("network", network);

    console.log("params", params.toString());

    let url = `/claim/${
      showPending ? "dump-list" : "list"
    }?${params.toString()}`;

    return url;
  }, [showPending, page, location.search, sortBy, network]);

  useEffect(() => {
    // Reset page when search is changed
    setPage(0);
  }, [location.search, sortBy, network]);

  const resetSearch = () => {
    navigate(`${location.pathname}`);
    setNetwork("");
    setSortBy("");
  };

  const { data, isLoading } = useFetch(url);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isToggleClick = (checked, _id) => {
    dumpAction(!checked, _id);
  };

  const dumpAction = async (status, _id) => {
    const url = "/claim/edit";
    const payload = { claimId: _id, isDisabledBit: status };
    const sendRequest = await services.Post(url, payload);
    if (sendRequest.status === 200) {
      return toaster(`${"Pool Status Updated Successfully"}`, "success");
    }
  };

  const copyToClipboard = (address) => {
    copy(address);
    return toaster(`${"Copied!"}`, "success");
  };

  return (
    <Page title="Manage Claims | Seedify Fund Snapshot">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Claim Pools
          </Typography>
          <ButtonList>
            <DropdownList
              title={network || "Select Network"}
              options={NETWORK_OPTIONS}
              onSelect={setNetwork}
            />

            <DropdownList
              title={`${SORT_OPTIONS[sortBy] || "Select Sort"}`}
              options={Object.keys(SORT_OPTIONS).map((value) => ({
                value,
                label: SORT_OPTIONS[value],
              }))}
              onSelect={setSortBy}
            />

            <Button
              variant="contained"
              className={showPending ? "active" : ""}
              onClick={() => [setPage(0), setShowPending(!showPending)]}
            >
              Pending
            </Button>

            {location.search && (
              <Button variant="outlined" onClick={resetSearch}>
                Reset Filters
              </Button>
            )}
          </ButtonList>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                />

                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <LoaderOuter>
                          <div className="loader"></div>
                        </LoaderOuter>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {!data?.data?.length ? (
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          No Data Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      data.data.map((row, index) => {
                        return (
                          <TableRow hover key={row._id} tabIndex={-1}>
                            <TableCell
                              style={{ padding: "14px", whiteSpace: "nowrap" }}
                              spacing={2}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "14px", whiteSpace: "nowrap" }}
                            >
                              {compactAddress(row.contractAddress)}
                              <span
                                style={{
                                  position: "relative",
                                  top: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                <Icon
                                  icon={copyOutline}
                                  onClick={() =>
                                    copyToClipboard(row.contractAddress)
                                  }
                                  height={18}
                                  width={18}
                                />
                              </span>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "14px", whiteSpace: "nowrap" }}
                            >
                              {compactAddress(row.tokenAddress)}
                              <span
                                style={{
                                  position: "relative",
                                  top: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                <Icon
                                  icon={copyOutline}
                                  onClick={() =>
                                    copyToClipboard(row.tokenAddress)
                                  }
                                  height={18}
                                  width={18}
                                />
                              </span>
                            </TableCell>

                            <TableCell
                              style={{
                                padding: "14px",
                                whiteSpace: "nowrap",
                                textTransform: "capitalize",
                              }}
                              align="left"
                            >
                              {row.networkName}
                            </TableCell>
                            <TableCell
                              style={{ padding: "14px", whiteSpace: "nowrap" }}
                              align="left"
                            >
                              {moment(row.timestamp * 1000).format(
                                "DD/MM/YYYY HH:mm:ss UTC"
                              )}
                            </TableCell>
                            <TableCell
                              style={{ padding: "14px", whiteSpace: "nowrap" }}
                              align="left"
                            >
                              {moment(row.endTime * 1000).format("DD-MM-YYYY ")}
                            </TableCell>
                            <TableCell
                              style={{ padding: "14px", whiteSpace: "nowrap" }}
                              align="left"
                            >
                              {row.phaseNo}
                            </TableCell>
                            <TableCell
                              style={{ padding: "14px", whiteSpace: "nowrap" }}
                              align="left"
                            >
                              {row.amount}
                            </TableCell>
                            <TableCell
                              style={{ padding: "14px", whiteSpace: "nowrap" }}
                              align="left"
                            >
                              {row.isPending ? row.data.length : "-"}
                            </TableCell>
                            {!showPending && (
                              <TableCell
                                align="center"
                                style={{
                                  padding: "14px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <CustomSwitch>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      value={row._id}
                                      defaultChecked={!row.isDisabledBit}
                                      onChange={(e) =>
                                        isToggleClick(
                                          e.target.checked,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="slider round" />
                                  </label>
                                </CustomSwitch>
                              </TableCell>
                            )}

                            <TableCell
                              align="right"
                              style={{ padding: "14px", whiteSpace: "nowrap" }}
                            >
                              <UserMoreMenu
                                menu="claimPool"
                                id={row._id}
                                url={`/dashboard/${
                                  row.networkName === "solana"
                                    ? "edit-solana-pool"
                                    : showPending
                                    ? "edit-pending-claim-pool"
                                    : "edit-claim-pool"
                                }/${row?._id}
                                   `}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {!isLoading && (
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={data?.pagination?.totalRecords || 0}
              rowsPerPage={20}
              page={page}
              onPageChange={handleChangePage}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
};

const CustomSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const LoaderOuter = styled.div`
  dispaly: flex;
  align-items: center;
  justify-content: center;
  .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #5bb3fc;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin: 20px auto;
    animation: spin 0.5s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const ButtonList = styled.div`
  display: flex;
  align-items: center;
  Button {
    margin: 0px 5px;
    &.active {
      background-color: #048cfc;
    }
  }
`;

const CompletedBox = styled.div`
  position: relative;
  .collapse-css-transition {
    position: absolute;
    top: 22px;
    right: 0px;
    left: auto;
    z-index: 9;
    width: 200px;
    transition: height 252ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  li {
    padding: 10px;
    cursor: pointer;
  }
`;

export default ManageClaims;
